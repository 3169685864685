/* eslint-disable camelcase */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import { graphql } from 'gatsby'
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import Breadcrumb from 'components/shared/Breadcrumb'
import Customer from 'components/shared/Customer'

// Elements
import Plaatjie from '@ubo/plaatjie'
import ParseContent from 'components/shared/ParseContent'

// Components
import SEO from 'components/SEO'
import Hero from 'components/shared/Hero'
import Layout from 'components/shared/Layout'
import GravityForm from 'components/GravityForm'
import Search from 'components/Check/Search'

// Media
import BottomLeft from 'img/background/bottom-left.inline.svg'

const Banner = styled.section`
  max-width: 1440px;
  margin: 0 auto;
  position: relative;
`

const BannerComp = css`
  position: absolute !important;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;

  @media (min-width: 1440px) {
    border-bottom-left-radius: 25px;
    border-bottom-right-radius: 25px;
    overflow: hidden;
  }
`

const BannerImage = styled(Plaatjie)`
  ${BannerComp};
  z-index: 1;

  & img {
    opacity: 1;
  }
`

const BannerOverlay = styled.div`
  ${BannerComp};
  z-index: 2;
  background: linear-gradient(
    270deg,
    rgba(114, 57, 149, 0.2) 0%,
    rgba(11, 189, 209, 0.8) 100%
  );
`

const BannerContent = styled.div`
  position: relative;
  z-index: 3;

  & h1 {
    font-weight: ${({ theme }) => theme.font.weight.l};
    color: ${({ theme }) => theme.color.text.light};

    & span {
      color: ${({ theme }) => theme.color.text.secondary} !important;
    }
  }
`

const BannerContentMap = styled.div`
  background-color: ${({ theme }) => theme.color.face.light};
  border-radius: 25px;
  overflow: hidden;

  @media (min-width: 992px) {
    border: 8px solid ${({ theme }) => theme.color.text.secondary};
    height: 450px;
    width: 450px;
  }

  @media (max-width: 991px) {
    border: 5px solid ${({ theme }) => theme.color.text.secondary};
    height: 400px;
    width: 400px;
  }

  @media (min-width: 768px) {
    position: absolute;
    bottom: -150px;
    right: 0.75rem;
  }

  @media (max-width: 767px) {
    height: 300px;
    width: 300px;
  }
`

const TerrainsContent = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }
`

const Terrains = styled.div`
  border-radius: 25px;

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }
`

const WhyImage = styled(Plaatjie)`
  @media (min-width: 768px) {
    width: 550px;
    height: 350px;
    position: absolute !important;
    right: -75px;
    top: -25px;
  }
`

const WhyContent = styled.div`
  background-color: ${({ theme }) => theme.color.face.main};
  position: relative;

  & h2,
  & p,
  & a {
    color: ${({ theme }) => theme.color.text.light};
  }

  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }

  @media (min-width: 768px) {
    &:after {
      background: ${(props) => props.theme.color.face.main};
      content: '';
      position: absolute;
      left: 100%;
      top: 0;
      bottom: 0;
      width: 2000px;
    }
  }
`

const QuestionsContent = styled(ParseContent)`
  & h2 {
    font-weight: ${({ theme }) => theme.font.weight.l};
  }
`

const FormBackground = styled(BottomLeft)`
  position: absolute;
  z-index: 1;
  left: -150px;
  bottom: 15px;
  width: 600px;
  height: 600px;

  & g {
    opacity: 1;
  }

  & path {
    fill: #517f92;
  }
`

const FormContent = styled(ParseContent)`
  & h2,
  & p,
  & a {
    color: #000;
    &:hover {
      color: #0bbdd2;
    }
  }
`

const Form = styled.div`
  border-radius: 15px;
  position: relative;
  z-index: 2;
`

const PageTemplate = ({
  data: {
    page: { title, path, acf, yoast_meta: yoast },
    bannerImg,
    ctaImg,
    whyImg,
  },
  pageContext,
}) => {
  const content = {
    image: { localFile: bannerImg },
    description: acf.banner_description,
  }

  const ctaContent = {
    title: 'Hein Wuite',
    acf: {
      preview: {
        description: '<p style="text-align: center;">Salesmanager</p>\n',
        email: 'hein.wuite@clearmind.nu',
        linkedin: 'https://www.linkedin.com/in/hein-wuite-4ba23054/',
        image: {
          localFile: ctaImg,
        },
      },
    },
  }

  const terrainHasGlassFiber =
    acf.glass_status === 'gereed' && acf.delta_fiber_status === 'gereed'

  const showFormDescription =
    acf.glass_status !== 'aangekondigd' &&
    acf.glass_status.toLowerCase() !== 'in onderzoek' &&
    acf.delta_status !== ''

  return (
    <Layout>
      <SEO
        yoast={yoast}
        path={path.replace('_', '/')}
        image={{
          url: bannerImg.childImageSharp.gatsbyImageData.images.fallback.src,
        }}
      />

      <Hero small className="mb-lg-3" content={content} />

      <Breadcrumb data={title} className="py-2" pageContext={pageContext} />

      <div className="container my-5">
        <div className="row">
          <div className="col-md-8">
            <ParseContent content={acf.content} />
          </div>
          <div className="col-md-4">
            <Customer content={ctaContent} />
          </div>
        </div>
      </div>
      {acf.glass_status === 'in uitvoering' || terrainHasGlassFiber ? (
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-10 mx-auto my-5">
              <ParseContent
                content={`
                  <h2><strong>Doe hier de postcodecheck</strong></h2>
                  <p>Zakelijk glasvezel internet voor een vast maandelijks bedrag. Doe de postcodecheck en bekijk wat er mogelijk is op uw locatie.</p>
                `}
              />
              <div className="mt-5">
                <Search />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="container position-relative">
            {/* <FormBackground /> */}
            <div className="row justify-content-start">
              <div className="col-lg-7 col-sm-10">
                {showFormDescription && (
                  <>
                    <FormContent
                      content={`
                <h2 class="mb-4" style="text-align: left; color: #000;"><strong>Vraag nu vrijblijvend een offerte aan!</strong></h2>
              `}
                    />
                    <FormContent
                      className="text-center"
                      content={`
                <p style="color: rgb(89, 90, 89); text-align: left;">
                Heeft u nog specifieke vragen? Wij beantwoorden ze uiteraard graag. Neem contact met ons op en u hoort snel van ons. We zijn telefonisch, via de mail en chat bereikbaar. Of misschien bent u benieuwd voor welke prijs u kunt kiezen voor zakelijk glasvezel internet van Clear Mind? Vraag dan nu vrijblijvend <a style="color: pink !important;" href="https://www.clearmind.nl/offerte-aanvragen/">een offerte aan</a> door het onderstaande formulier in te vullen. 
                </p>
              `}
                    />
                  </>
                )}

                <div className="mt-5">
                  <Form className="color-background-secondary p-4">
                    <GravityForm
                      id={9}
                      defaultValues={{
                        input_5: title,
                      }}
                      split={false}
                    />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </Layout>
  )
}

const googleApiKey = 'AIzaSyBl_xlo5FoGFzF8dfIxb29G-bpWVEoOB6o'

const GoogleMaps = ({ region = '' }) => {
  return (
    <iframe
      width="100%"
      height="100%"
      frameBorder="0"
      src={`https://www.google.com/maps/embed/v1/place?key=${googleApiKey}&q=${region.replace(
        'Glasvezel ',
        ''
      )}&language=nl-NL&maptype=roadmap&region=nl`}
    />
  )
}

const items = [
  {
    number: 1,
    description: `
      <h3>Stap 1: <span class="color-contrast">Doe de postcodecheck</span></h3>
      <p>Vul uw postcode, huisnummer in en check de mogelijkheden op uw adres.</p>
    `,
  },
  {
    number: 2,
    description: `
      <h3>Stap 2: <span class="color-contrast">Kies uw bedrijf en start de offerteaanvraag</span></h3>
      <p>Selecteer op uw adres uw bedrijf en bekijk welke verbindingen er voor u beschikbaar zijn.</p>
    `,
  },
  {
    number: 3,
    description: `
      <h3>Stap 3: <span class="color-contrast">Kies de verbinding die bij u past</span></h3>
      <p>Selecteer de contractduur, snelheid, service en leverdatum van de door uw gewenste verbinding. Kies vervolgens, indien gewenst, ook uit verschillende aanvullende opties en verstuur uw persoonlijke aanvraag.</p>
    `,
  },
  {
    number: 4,
    description: `
      <h3>Stap 4: <span class="color-contrast">Digitaal ondertekenen van de offerte</span></h3>
      <p>-	U ontvangt direct een passende offerte op de mail. Akkoord? Ondertekenen doet u eenvoudig, snel en geheel online via iDIN. Identificeer uzelf door in te loggen bij uw bank, onderteken de offerte digitaal en geef een incassomachtiging af.</p>
    `,
  },
  {
    number: 5,
    description: `
      <h3>Stap 5: <span class="color-contrast">Supersnel zakelijk internet</span></h3>
      <p>Op de gewenste leverdatum komt één van onze engineers bij u langs om uw nieuwe zakelijke verbinding te installeren. Binnen no-time ben jij weer up & running en profiteer ook jij van supersnel zakelijk internet!</p>
    `,
  },
]

const StepNumber = styled.button`
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 4px solid ${({ theme }) => theme.color.text.main};
  font-weight: ${({ theme }) => theme.font.weight.xl};
  color: ${({ theme }) => theme.color.text.main};
  transition: all 0.3s ease-in-out;
  opacity: ${(props) => (props.active ? 1 : 0.15)};

  &:hover {
    opacity: ${(props) => (props.active ? 1 : 0.5)};
  }

  @media (min-width: 992px) {
    font-size: ${({ theme }) => theme.font.size.xxxl};
    height: 125px;
    width: 125px;
  }

  @media (max-width: 991px) {
    font-size: ${({ theme }) => theme.font.size.xxl};
    height: 100px;
    width: 100px;
  }

  @media (max-width: 767px) {
    font-size: ${({ theme }) => theme.font.size.xl};
    height: 75px;
    width: 75px;
  }
`

const StepContent = styled(motion.div)`
  overflow: hidden;
`

const StepContentBlock = styled(ParseContent)`
  border-radius: 15px;
`

export const pageQuery = graphql`
  query ($wordpress_id: Int) {
    page: wordpressWpBedrijventerreinen(wordpress_id: { eq: $wordpress_id }) {
      title
      path

      acf {
        glass_status
        delta_fiber_status
        banner_description
        content
        cta
      }

      yoast_meta {
        name
        content
        property
      }
    }

    bannerImg: file(relativePath: { eq: "region-banner-holder.jpeg" }) {
      childImageSharp {
        gatsbyImageData(width: 1920)
      }
    }

    whyImg: file(relativePath: { eq: "region-why-holder.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }

    ctaImg: file(relativePath: { eq: "Hein-Wuite.png" }) {
      childImageSharp {
        gatsbyImageData(width: 650)
      }
    }
  }
`

export default PageTemplate
